<template>
    <div>
        <br />
        <div class="box container">
               <div @click="$router.push('/dashboard')" style='text-align:left; cursor:pointer; '> <i  style=" " class="fas fa-chevron-circle-left"></i> Back</div><br />
            <h3>Create Team</h3> 
            <hr />
             <div class="form-group">
    <label >Team Name: </label>
    <input type="text" class="form-control" v-model="name"  placeholder="Team Name">
  </div>  <br />
  <p>Sport:</p> <select class="form-select"  v-model="sport">
               
    <option value="Baseball">Baseball</option>
      <option value="Softball">Softball</option>
    <option value="Volleyball">Volleyball</option>
     <option value="Basketball">Basketball</option>
    <option value="Football">Football (American)</option>
    <option value="Soccer">Soccer</option>
      <option value="Tennis">Tennis</option>
    <option value="Wrestling">Wrestling</option>
     <option value="Hockey">Hockey</option>
      <option value="Rugby">Rugby</option>
    <option value="Track">Track</option>
            <option value="Other">Other</option>
  
  </select>
  <br />
      <input type="text" v-if="sport == 'Other'" class="form-control"  v-model="sport_other" placeholder="Enter Sport Name">
  <br v-if="sport == 'Other'" />
           <div class="form-group">
    <label >City Location: </label>
  <vue-google-autocomplete types="(cities)"
    id="map"
  
    classname="form-control"
    placeholder="Start typing"
    v-on:placechanged="getAddressData"
>
</vue-google-autocomplete> 
  </div><br />
          <button @click="createTeam" class="btn  btn-success" >Create Team</button>

        </div>
        <br /><br />
    </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import firebase from "firebase";
require("firebase/firestore");
var db = firebase.firestore();

export default {
      name: "CreateTeam",
      components:{
          VueGoogleAutocomplete
      },
      data() {
    return {
     address: undefined,
     sport: "Football",
     sport_other: "",
     name: "",
     user: undefined

    }
      },
      mounted: function(){
               firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
     
      } else {
        this.user = null;
      }
    });
      },
      methods:{
          getAddressData: function (addressData, placeResultData) {
                console.log(placeResultData)
                this.address = {formatted_address: placeResultData.formatted_address, latitude: addressData.latitude, longitude:addressData.longitude, extended: addressData};
            },
       async   createTeam(){
            if (this.name.length == 0){ return}
 await db.collection("Teams").add({
    name: this.name,
    events: [],
    author_uid: this.user.uid,
    can_edit: [this.user.uid],
    members: [this.user.uid],
    location: this.address,
    sport: this.sport != "Other" ? this.sport : this.sport_other,
    access_pin: Math.floor(1000000 + Math.random() * 9000000)
})
        this.$router.push('/dashboard')

      }
      }
}
</script>

<style>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
    text-align: left;
}


</style>